<template>
  <div class="policy-wrapper text-left p-5">
    <div class="fs-3 fw-bolder py-2">Privacy & Cookie Notice</div>

    <div class="fs-5 fw-bold pt-5">Our Privacy Promise</div>
    <div class="pt-3">
      Our Privacy and Cookie Notice below sets out in detail how we process your personal
      data, and we encourage you to read it in full. However, we also understand that
      people have busy lives and won't always find the time to read everything online
      immediately, and so wanted to help you by providing a brief overview of how and
      why we process your personal data.
    </div>
    <div class="pt-3">
      We mainly only collect and process your personal data for two reasons: to provide
      you with services and to comply with legal and regulatory obligations.
    </div>

    <div class="fs-5 fw-bold pt-5">1. Introduction</div>
    <div class="pt-3">
      We are committed to protecting your privacy and personal data. This Privacy and
      Cookie Notice (the Notice) will inform you as to how we look after your personal data
      when you visit our website (regardless of where you visit it from) and tell you about
      your privacy rights and how the law protects you. We may modify this Notice from
      time to time. Any changes to our Notice will become effective upon posting of the
      revised Notice on the Website and we will notify you of any material changes to this
      Notice. We recommend that you review this Notice from time-to-time.
    </div>
    <div class="pt-3">
      This Notice should be read together with our general “Terms of Use” (as defined in
      our Website Terms and Conditions available on our Website and any other
      documents referred to on it).
    </div>
    <div class="pt-3">
      It is important that the personal data we hold about you is accurate and current.
      Please keep us informed if your personal data changes during your relationship with
      us.
    </div>

    <div class="fs-5 fw-bold pt-5">2. Data Controller</div>
    <div class="pt-3">
      Our legal status under data protection law is that of a controller (also known as data controller) and in this
      capacity, we will securely store and process your personal data which you have provided to us. Controller is the
      legal term used to signify the organisation that controls what to do with any given personal data.
      If you have a concern or any questions about our handling of your personal data, please get in contact our DPO
      with us first so we can try to resolve your query using the email <a
        href="mailto:info@zemeto.co">info@zemeto.co</a>. If, however you feel we have not
      dealt with your concern and that we are failing to meet our legal obligations, you can report this to the
      supervisory authority: the Office of the Information and Data Protection Commissioner (IDPC) that governs our
      company in relation to data protection issues. IDPC:<a
        href="https://idpc.org.mt/en/Pages/contact/complaints.aspx">https://idpc.org.mt/en/Pages/contact/complaints.aspx</a>
    </div>

    <div class="fs-5 fw-bold pt-5">3. Information we collect about you</div>
    <div class="pt-3">
      Personal data, or personal information, means any information about an individual
      from which that person can be identified. It does not include data where the identity
      has been removed (anonymous data). We may collect, use, store and transfer
      different kinds of personal data about you which includes:
    </div>
    <div class="pt-3">
      Identity Data includes first name, last name, username or similar identifier, marital
      status, title, date of birth and gender.
    </div>
    <div class="pt-3">
      Contact Data includes billing address, email address and telephone number as well
      as any other information provided at the time of registering to use our Website,
      subscribing to any of our services (including promotions about products), posting
      material or requesting further services.
    </div>
    <div class="pt-3">
      Correspondence Data includes information you provide when entering a promotion
      or competition, making a Customer Support enquiry or complaint or when you report
      a problem with our Website, any other records of correspondence if you contact us
      about any matter. Your telephone conversations may be recorded both to assist us in
      training and in quality management, and to allow for the speedy resolution of
      queries.
    </div>
    <div class="pt-3">
      Financial Data includes bank account information.
      Transaction Data includes details about payments to and from you and other details
      of products and services you have purchased from us.
    </div>
    <div class="pt-3">
      Technical Data includes internet protocol (IP) address, your login data, browser type
      and version, time zone setting and location, browser plug-in types and versions,
      operating system and platform and other technology on the devices you use to
      access this website.
    </div>
    <div class="pt-3">
      Profile Data includes your username and password and wagering completed by you.
      Usage Data includes information about how you use our website.
    </div>
    <div class="pt-3">
      Marketing and Communications Data includes your preferences in receiving
      marketing from us and our third parties and your communication preferences, as can
      be found in the My Account section.
    </div>
    <div class="pt-3">
      We also collect, use and share Aggregated Data such as statistical or demographic
      data for any purpose. Aggregated Data may be derived from your personal data but
      is not considered personal data in law as this data does not directly or indirectly
      reveal your identity.
    </div>
    <div class="pt-3">
      We may also collect certain special categories of personal data about you (such as
      details about your health) and information about criminal convictions and offences.
      This information is not collected by us in the ordinary course of business but only in
      exceptional circumstances and shall only be processed to comply with legal or
      regulatory obligations and responsibilities or where you have provided this
      information to us.
    </div>

    <div class="fs-5 fw-bold pt-5">4. If you fail to provide personal data</div>
    <div class="pt-3">
      Where we need to collect personal data by law, or under the terms of a contract we
      have with you and you fail to provide that data when requested, we may not be able
      to perform the contract we have or are trying to enter into with you (for example, to
      provide you with the services via our website). In this case, we may have to cancel a
      product or service you have with us but we will notify you if this is the case at the
      time.
    </div>

    <div class="fs-5 fw-bold pt-5">5. How is your personal data collected?</div>
    <div class="pt-3">
      We use different methods to collect data from and about you including through:
    </div>
    <div class="pt-3">
      Direct interactions. You may give us personal data by filling in forms or by
      corresponding with us. This includes personal data you provide when you create an
      account on our website; place bets, or wager funds, on a game, via our website;
      request marketing to be sent to you; enter a competition, promotion or survey; or
      give us some feedback.
    </div>
    <div class="pt-3">
      Automated technologies or interactions. As you interact with our website, we may
      automatically collect data about your equipment, browsing actions and patterns,
      which may include personal data. We collect this personal data by using cookies,
      server logs and other similar technologies. Please see our cookie section below for
      more information.
    </div>
    <div class="pt-3">
      Third parties or publicly available sources. We may receive personal data about you
      from various third parties and public sources to supplement personal data we hold
      about you for the processing purposes of prevention or detection of crime as well as
      responsible gambling as described in section 6 below.
    </div>

    <div class="fs-5 fw-bold pt-5">6. How we use of your information</div>
    <div class="pt-3">
      In accordance with data protection laws, we will only process your personal data
      where we have a lawful basis for doing so. In respect of your personal data, these
      bases are: (i) where it is necessary to provide services to you under the performance
      of the contract we have with you; (ii) where we are required to do so in accordance
      with legal or regulatory obligations; (iii) where you have given your consent; and (iv)
      where it is in our legitimate interests to process your personal data provided that
      these interests do not prejudice your own rights, freedoms and interests.
    </div>
    <div class="pt-3">
      We may use non-personal information (which is non-identifiable information that,
      when taken alone, cannot be used to identify or contact you). This could include
      gameplay information, technical information and analytics information. As such, we
      are not aware of the identity of the user from which the non-personal information
      was collected. We use such information for different purposes, such as advertising,
      improvement of our services, preventing fraud and performing web analytics. We
      may gather such information, either independently or through the help of our third-
      party service providers.
    </div>
    <div class="pt-3">
      Where we process your personal data as set out above, we may do this directly or
      appoint members of our group or service providers (known as processors) to do this
      on our behalf and all of whom adhere to this Notice, and who only process your
      personal data on our behalf for the purposes set out above.
    </div>

    <div class="fs-5 fw-bold pt-5">7. Data transfer</div>
    <div class="pt-3">
      Since we operate globally, it may be necessary to transfer your Information to
      countries outside the European Union. The data protection and other laws of these
      countries may not be as comprehensive as those in the European Union − in these
      instances we will take steps (such as via standard contractual clauses pursuant to
      European Commission Decision 2010/87/EU available here) to ensure that an
      adequate level of protection is given to your personal data.
    </div>

    <div class="fs-5 fw-bold pt-5">8. Marketing</div>
    <div class="pt-3">
      We, as a Group, may use your information, such as your name, home address, email
      address, telephone number etc, ourselves or by using our third party subcontractors
      for the purpose of providing you with promotional materials, concerning our Website
      as well as products, services, websites and applications which relate to: (i) other
      companies within the Group; (ii) White Label Brands; or (iii) Group's business
      partners and affiliates (together the ‘Marketing Affiliates’), which we believe may
      interest you.
    </div>
    <div class="pt-3">
      We may also share and disclose Information with our Marketing Affiliates for the
      purpose of providing you different marketing offers, which we, or our Marketing
      Affiliates, believe are relevant for you. Our Marketing Affiliates may use this
      information for different marketing techniques, such as direct email, post, SMS and
      telephone marketing purposes.
    </div>
    <div class="pt-3">
      We will only send you marketing communications where you have consented to
      receive such communications. You may at any time decline receiving further
      marketing offers from us or from our business partners by contacting us or following
      the links in the relevant communication to unsubscribe. Please note that even if you
      unsubscribe from our marketing mailing list, we may continue to send you service-
      related updates and notifications.
    </div>

    <div class="fs-5 fw-bold pt-5">9. Monitoring transactions</div>
    <div class="pt-3">
      All bets, gaming and account transactions are recorded on our computer systems.
      We also carry out routine monitoring of all transactions as they take place to assist
      us in improving the service that we provide to you and to comply with our licensing
      and regulatory obligations.
    </div>

    <div class="fs-5 fw-bold pt-5">10. Security</div>
    <div class="pt-3">
      We employ security measures to protect your personal data from access by
      unauthorised persons and to prevent unlawful processing, accidental loss,
      destruction and damage. These safeguards vary based on the sensitivity of the
      information that we collect and store. Protection of your personal data, including
      your registration details and any debit or credit card information you provide to us, is
      a priority for us.
    </div>
    <div class="pt-3">
      Data on our secure pages is encrypted using TLS v1.2 at a minimum. Where we have
      given you (or where you have chosen) a password which enables you to access
      certain parts of our Websites, you are responsible for keeping this password
      confidential.
    </div>
    <div class="pt-3">
      Any personal data which identifies you will always be encrypted whilst in transit over
      the internet. SSL is a special protocol that automatically encrypts any information
      sent by you on our secure pages. Our server then reads the information using a
      private key. This means that your personal data, be it debit or credit card
      information or any other personal details, is turned into a code that can only be
      decoded with the private key or password. If your browser is suitably configured, you
      will receive a warning message when you first access any secure page on the
      Website. This is simply information to advise you that the Website is protecting your
      Information.
    </div>
    <div class="pt-3">
      Unfortunately, the transmission of Information via the internet is not completely
      secure. Although we will do our best to protect your Information, we cannot
      guarantee the security of any personal data that you disclose online. You accept the
      inherent security implications of using the internet and will not hold us responsible
      for any breach of security unless we have failed in our obligations under data
      protection legislation. For your own privacy protection, we encourage you not to
      include sensitive Information (such as credit/debit card numbers) in any emails you
      may send to us.
    </div>

    <div class="fs-5 fw-bold pt-5">11. Retention</div>
    <div class="pt-3">
      We will hold your personal data for so long as you use our service and for no more
      than five years after (unless required to be kept longer in accordance with legal and
      regulatory requirements). We will endeavour to delete any personal data sooner
      where it is not necessary for us to hold this, but please be aware that we may hold
      personal data for longer if we are under a legal obligation to do so or where we have
      a reasonable belief that it is necessary to do so for business or legal reasons.
    </div>

    <div class="fs-5 fw-bold pt-5">12. Your legal rights, fees and what we may need from you</div>
    <div class="pt-3">
      Requests: Under certain circumstances, you have rights under data protection laws in relation to your personal
      data. If you wish to exercise any of the rights set out below, please contact us at <a
        href="mailto:info@zemeto.co">info@zemeto.co</a>. Please note,
      however, that not all rights are absolute.
    </div>
    <div class="pt-3">
      Fees: You will not have to pay a fee to access your personal data (or to exercise any
      of the other rights). However, we may charge a reasonable fee if your request is
      clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply
      with your request in these circumstances.
    </div>
    <div class="pt-3">
      What we may need from you: We may need to request specific information from you
      to help us confirm your identity and ensure your right to access your personal data
      (or to exercise any of your other rights). This is a security measure to ensure that
      personal data is not disclosed to any person who has no right to receive it. We may
      also contact you to ask you for further information in relation to your request to
      speed up our response.
    </div>
    <div class="pt-3">
      Time limit to respond: We try to respond to all legitimate requests within one month.
      Occasionally it may take us longer than a month if your request is particularly
      complex or you have made a number of requests. In this case, we will notify you and
      keep you updated.
    </div>
    <div class="pt-3">
      Your legal rights:
    </div>
    <div class="pt-3">
      Request access to your personal data (commonly known as a "data subject access
      request"). This enables you to receive a copy of the personal data we hold about you
      and to check that we are lawfully processing it.
    </div>
    <div class="pt-3">
      Request correction of the personal data that we hold about you. This enables you to
      have any incomplete or inaccurate data we hold about you corrected, though we
      may need to verify the accuracy of the new data you provide to us.
    </div>
    <div class="pt-3">
      Request erasure of your personal data. This enables you to ask us to delete or
      remove personal data where there is no good reason for us continuing to process it.
      You also have the right to ask us to delete or remove your personal data where you
      have successfully exercised your right to object to processing (see below), where we
      may have processed your information unlawfully or where we are required to erase
      your personal data to comply with local law. Note, however, that we may not always
      be able to comply with your request of erasure for specific legal or regulatory
      reasons which will be notified to you, if applicable, at the time of your request.
    </div>
    <div class="pt-3">
      Object to processing of your personal data where we are relying on a legitimate
      interest (or those of a third party) and there is something about your particular
      situation which makes you want to object to processing on this ground as you feel it
      impacts on your fundamental rights and freedoms. You also have the right to object
      where we are processing your personal data for direct marketing purposes. In some
      cases, we may demonstrate that we have compelling legitimate grounds to process
      your information which override your rights and freedoms.
    </div>
    <div class="pt-3">
      Request restriction of processing of your personal data. This enables you to ask us to
      suspend the processing of your personal data in the following scenarios: (a) if you
      want us to establish the data's accuracy; (b) where our use of the data is unlawful
      but you do not want us to erase it; (c) where you need us to hold the data even if we
      no longer require it as you need it to establish, exercise or defend legal claims; or (d)
      you have objected to our use of your data but we need to verify whether we have
      overriding legitimate grounds to use it.
    </div>
    <div class="pt-3">
      Request the transfer of your personal data to you or to a third party. We currently do
      not offer this service and we are obliged to tell you pursuant to this Notice that this is
      not currently available.
    </div>
    <div class="pt-3">
      Withdraw consent at any time where we are relying on consent to process your
      personal data. However, this will not affect the lawfulness of any processing carried
      out before you withdraw your consent. If you withdraw your consent, we may not be
      able to provide certain products or services to you. We will advise you if this is the
      case at the time you withdraw your consent.
    </div>

    <div class="fs-5 fw-bold pt-5">13. Opt-out</div>
    <div class="pt-3">
      From time to time, we may use player contact details to send you information about
      our services, which you accessed or subscribed to, including, but not limited to news
      about product updates, events, promotional materials and contests. You can opt out
      of these communications should you wish. Additionally, it is also possible to
      discontinue all general communications whilst receiving important update messages.
      For ease, each communication from us will include instructions for discontinuing the
      receipt of further communications. Please note that we may send you e-mails on
      certain topics which you will not be eligible to unsubscribe from as we consider them
      an essential part of the service.
    </div>

    <div class="fs-5 fw-bold pt-5">14. Third party collection of information</div>
    <div class="pt-3">
      Please keep in mind that this Notice does not apply to any third-party websites,
      services or applications, even if they are accessible, downloadable, or otherwise
      distributed through the Website. You should always review their privacy practices
      carefully before providing personal information to such third parties.
    </div>

    <div class="fs-5 fw-bold pt-5">15. Corporate transaction</div>
    <div class="pt-3">
      We may share Information in the event of a corporate transaction (e.g. sale of a
      substantial part of our business, merger, reorganization, bankruptcy, consolidation or
      asset sale of an asset or transfer in the operation thereof) in relation to any company
      within the Group. In the event of the above, the acquiring company or transferee will
      assume the rights and obligations as described in this Notice.
    </div>

    <div class="fs-5 fw-bold pt-5">16. Disclosure for legal reasons</div>
    <div class="pt-3">
      We may occasionally be required by law, court order or governmental authority to
      disclose certain types of personal data. Examples of the type of situations where
      this would occur would be:
    </div>
    <div class="pt-3">
      in the administration of justice; or where we have to defend ourselves legally.
    </div>

    <div class="fs-5 fw-bold pt-5">17. Cookies and Pixels Notice</div>
    <div class="pt-3">A: Cookies</div>
    <div class="pt-3">
      A 'cookie' is a small text file containing small amounts of information which is
      downloaded to your device when you visit a website or use applications. The cookie
      enables the server to collect information from the browser. We may use the following
      types of cookies:
    </div>
    <div class="pt-3">
      ESSENTIAL COOKIES: Cookies that are essential to the running of a Website. Without
      the use of cookies, parts of the Website would not function correctly. For example,
      when logging in to your account a session is created to allow you to browse the site
      and play games;
    </div>
    <div class="pt-3">
      ANALYTICAL COOKIES: We use analytical cookies to monitor our Website
      performance and to determine how visitors use the site. These cookies provide us
      with information that helps us create better products for our users and to identify
      areas that may need maintenance. We currently use Google Analytics, a popular web
      analytics service provided by Google, Inc. Google Analytics uses cookies to help us to
      analyse how players use our Website. We also use analytical cookies from additional
      third-party providers;
    </div>
    <div class="pt-3">
      PERSISTENT COOKIES: These are saved on your computer for a fixed period and are
      not deleted when the browser is closed. Such cookies are used where we need to
      know who you are for repeat visits, so the correct language is presented and to
      improve our users’ experience of the Website as well as present different marketing
      messages and advertisements for existing users;
    </div>
    <div class="pt-3">
      FUNCTIONAL COOKIES: Functional cookies are used to remember your preferences
      on our Website, enhancing the user experience. This can have a range of functions
      such as remembering your username or recording that you have read a site
      message so you don’t need to view it again;
    </div>
    <div class="pt-3">
      SESSION COOKIES: Session cookies are used by the server to store information about
      user page activities so users can easily pick up where they left off on the server's
      pages.
    </div>
    <div class="pt-3">
      We use a number of different cookies on this Website to help us to recognise you,
      track your activity and improve your use and experience of the Website. In addition,
      we use a number of third party service providers, who also set cookies on this
      Website, in order to deliver the services that they are providing to us. Such services
      include, but are not limited to, helping us to improve your use and experience of the
      Website by tracking your activity on the Website, measuring the effectiveness of the
      Website and the effectiveness of our marketing campaigns.
    </div>
    <div class="pt-3">
      We and/or third parties may use cookies to collect or receive information from the
      Website and use that information to advertise to you on other websites. You can
      view the cookies used for such purposes on this Website and opt out of the collection
      and use of your information for such targeted advertising
      here: www.aboutads.info/choices
    </div>
    <div class="pt-3">
      Some people find the idea of a website storing information on their computer or
      mobile (or other) device intrusive, particularly when this information is stored and
      used by a third party without them knowing. Although this is generally quite
      harmless, you may not, for example, want to see advertising that has been targeted
      to your interests. If you prefer, it is possible to block some or all cookies, or even to
      delete cookies that have already been set but you need to be aware that you might
      lose some functions of that website. Most online browsers automatically accept
      cookies. However, if you wish to restrict or block cookies you can modify your
      browser settings.
    </div>
    <div class="pt-3">B: Pixels</div>
    <div class="pt-3">
      We also use pixel-based tracking to track a visit or event on the platform and to
      track advertisements' impressions. This information is also used by us for
      remarketing purposes and for tracking conversions that are used to optimize our
      marketing activities. We may share such information (which is non-personal
      information) with our advertising and marketing partners. Please see specific
      information about our marketing practices in section 8. By accessing and using the
      Website and services, you consent to the placement of cookies and pixels on your
      device in accordance with this Notice.
    </div>
  </div>
</template>


<script>
import config from "@/config";

export default {
  beforeMount() {
    this.initClarity(config.clarityId.onboardingId)
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.scrollToTop();
  },
}
</script>


<style lang="scss" scoped>
.policy-wrapper {
  div {
    word-break: break-word;
  }
}

.text-left {
  text-align: left;
}
</style>
